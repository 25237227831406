import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Emotion from 'emotion';
import moment from 'moment-timezone';
import { COLORS } from 'constants/colors';
import { createEventNotification } from 'containers/NotificationContainer/notificationsStore/actions';
import { setOpenedModal } from 'containers/ModalContainer/modalsStore/actions';
import exportToPdf from 'containers/PdfContainer/utils/exportToPdf';
import {
  columnMediumSmall, columnSmall, container, flexColumn, flexRow, mt3,
} from '../../assets/soter-flex-styles';
import UserContainer from './UserContainer/UserContainer';
import ProgramContainer from './ProgramContainer/ProgramContainer';
import { USER_EXPORT_PDF, USER_NOTE_CHANGED, USER_PDF_DOWNLOADED } from '../../constants/analyticsEvents';
import usePrevious from '../../lib/utils/usePreviuos';
import LinkToEntry from '../../lib/ui/LinkToEntry/LinkToEntry';
import NavigateBackButton from '../../lib/ui/NavigateBackButton';
import ActionToggleButton from '../../lib/ui/ActionToggleButton';
import UserActionDropdown from './UserContainer/UserActionDropdown';
import { TableType } from '../../containers/ItemTableContainer/constants/tableType';
import DropToggler from '../../containers/ItemTableContainer/components/DropToggler';
import ProgramSelector from './UserContainer/ProgramSelector';
import EditableTextCard from '../../lib/ui/EditableTextCard/EditableTextCard';
import { getDatesOfSessions } from './ProgramContainer/utils/getDatesOfSessions';
import UserPdfContainer from './PdfReportContainer/UserPdfContainer';
import Loader from '../../lib/ui/Loader';
import { setNote } from './userStore/actions';
import { ChartFactoryContext } from '../overview/utils/d3/chartFactoryContext';
import { escapeHTML } from '../../lib/ui/EditableTextCard/escapeHTML';
import EditUserDialog from '../activity/components/EditUserDialog';
import { useAppSelector } from '../../lib/utils/useStore';

const textCard = Emotion.css`
  flex-shrink: 2;
`;

const CoachUserPageContainer = ({
  user,
  chartFactory,
  navigateBackText,
  hazardsByDay,
  hazardsConfig,
  timezone,
}) => {
  const dispatch = useDispatch();
  const { reportPeopleNames } = useAppSelector((state) => state.companies.selectedCompany);
  const { t } = useTranslation();
  const passedPrograms = user?.pastProgramsList;
  const hasPassedPrograms = !!passedPrograms?.length;
  const { selectedCompany } = useSelector((state) => state.companies);
  const { categories } = useSelector((state) => state.category);
  const filteredCategories = categories && categories.filter((category) => category.title === 'department'
    || category.title === 'user_job_role' || category.title === 'site');
  const [userForTagShown, setUserForTagShown] = useState(false);
  const [currentProgramId, setCurrentProgramId] = useState(
    user?.currentProgram?.coachingProgramPassId,
  );
  useEffect(() => {
    if (!currentProgramId && user?.currentProgram) {
      setCurrentProgramId(user.currentProgram.coachingProgramPassId);
    }
  }, [user]);
  const fullProgramList = [user?.currentProgram].concat(
    user?.pastProgramsList || [],
  ).filter((program) => program);
  const [selectedProgram, setSelectedProgram] = useState(user?.currentProgram || {});
  const previousProgramId = usePrevious(currentProgramId);
  useEffect(() => {
    (user?.currentProgram || currentProgramId) &&
      setSelectedProgram(
        fullProgramList.find(
          ({ coachingProgramPassId }) => coachingProgramPassId === currentProgramId,
        ),
      );
    chartFactory
      .getChartDrawer(
        'stacked',
        `hazards-per-hour-stacked-chart-${previousProgramId}`,
      )
      .clearChart();
    chartFactory
      .getChartDrawer(
        'stacked',
        `hazards-per-session-bar-chart-${previousProgramId}`,
      )
      .clearChart();
  }, [currentProgramId, hazardsByDay]);
  const userActions = [
    {
      id: 'linkToEntry',
      name: t('WEARABLES.ACTIVITY.ACTIONS.LINK'),
      color: COLORS.GRAY[400],
      size: 'small',
      child: (
        <LinkToEntry
          title={t('WEARABLES.ACTIVITY.ACTIONS.LINK')}
          categories={filteredCategories}
          companyId={selectedCompany?.id}
          setOpenedModal={setOpenedModal}
          userIds={[user?.id]}
          setUserForTagShown={setUserForTagShown}
        />
      ),
      disabled: false,
    },
    {
      id: 'pdfExport',
      name: t('WEARABLES.OVERVIEW.EXPORT_PDF'),
      color: COLORS.GRAY[400],
      size: 'small',
      disabled: !selectedProgram?.improvements?.averageHazardousMovementValue,
    },
  ];
  reportPeopleNames && userActions.push({
    id: 'edit',
    name: t('DASHBOARD_USER.EDIT'),
    color: COLORS.GRAY[400],
    size: 'small',
    child: (
      <EditUserDialog
        users={[user]}
        title={t('MY_COMPANY.USERS.EDIT')}
      />
    ),
  });
  const togglerButton = <ActionToggleButton id={user?.id} />;
  return user ? (
    <div className={container}>
      <div className={`${flexRow} ${mt3}`}>
        <div
          className={columnSmall}
          style={{
            justifyContent: 'flex-start',
          }}
        >
          <NavigateBackButton
            name={navigateBackText}
            navigateTo="../../../users/wearables/activity"
            goBackStepHistory={!sessionStorage.getItem('initPage')}
          />
        </div>
      </div>
      <UserContainer user={user} timezone={timezone}>
        <UserActionDropdown
          userActions={userActions}
          itemType={TableType.user}
          exportToPdf={() => {
            window.logEvent(USER_PDF_DOWNLOADED);
            window.logEvent(USER_EXPORT_PDF);
            if (localStorage.getItem('showEvents')) {
              dispatch(createEventNotification(USER_PDF_DOWNLOADED));
              dispatch(createEventNotification(USER_EXPORT_PDF));
            }
            exportToPdf(
              user.firstName,
              user.lastName,
              `#user_report_${selectedProgram.id}`,
              'l',
            );
          }}
        >
          <DropToggler
            id={`user-dropdown-${user?.id}`}
            togglerButton={togglerButton}
            content={t('WEARABLES.USER_PAGE.USER_ACTIONS')}
            direction="down"
            disabled={false}
          />
        </UserActionDropdown>
      </UserContainer>
      <div style={{ display: 'flex', height: '222px', marginTop: '12px' }}>
        {hasPassedPrograms ? (
          <div className={flexColumn}>
            <ProgramSelector
              currentProgram={currentProgramId}
              setCurrentProgram={setCurrentProgramId}
              programList={fullProgramList}
            />
          </div>
        ) : null}
        <div className={hasPassedPrograms ? columnMediumSmall : flexColumn}>
          <EditableTextCard
            tooltipText={[
              t('WEARABLES.USER_PAGE.TOOLTIPS.NOTES_1'),
              t('WEARABLES.USER_PAGE.TOOLTIPS.NOTES_2'),
              t('WEARABLES.USER_PAGE.TOOLTIPS.NOTES_3'),
              t('WEARABLES.USER_PAGE.TOOLTIPS.NOTES_4'),
            ]}
            title={t('WEARABLES.USER_PAGE.NOTES')}
            className={textCard}
            height={222}
            id={`edit_note_${user?.id}`}
            saveValue={(text) => {
              window.logEvent(USER_NOTE_CHANGED);
              if (localStorage.getItem('showEvents')) {
                dispatch(createEventNotification(USER_NOTE_CHANGED));
              }
              dispatch(setNote(user?.id, escapeHTML(text)));
            }}
            text={user?.note?.text}
          />
        </div>
      </div>
      <ChartFactoryContext.Provider value={chartFactory}>
        {selectedProgram ? (
          <ProgramContainer
            user={user}
            program={selectedProgram}
            hazardsByDay={selectedProgram.hazardsByDayList}
            hazardsConfig={hazardsConfig}
            dates={
              selectedProgram?.hazardsByDayList
                ? getDatesOfSessions(
                  selectedProgram?.hazardsByDayList,
                  user?.timezone || moment.tz.guess(),
                )
                : []
            }
            timezone={timezone}
          />
        ) : null}
      </ChartFactoryContext.Provider>
      {selectedProgram?.improvements && (
        <UserPdfContainer
          currentProgram={selectedProgram}
          user={user}
          isGrayScale={false}
        />
      )}
    </div>
  ) : (
    <Loader />
  );
};

export default CoachUserPageContainer;
